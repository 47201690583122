// pages/Listings.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllListings } from '../slices/listingsSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InstallPwa from '../components/InstallPwa';
import {
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Alert,
  Container,
  CardHeader,
  CardActionArea,
  SnackbarContent,
  Button,
  LinearProgress,
  Box
} from '@mui/material';
import Category from '../components/Category';
import InstallPwaCard from '../components/InstallPwaCard';
import CreateListingCard from '../components/CreateListingCard';

const Listings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listings, loading, error } = useSelector((state) => state.listings);

  const [isVisible, setIsVisible] = useState(true);
  const [activeListings, setActiveListings] = useState(null);


  useEffect(() => {
    const fetchListings = () =>
      isVisible && dispatch(fetchAllListings({ navigate }));

    const fetchActiveListings = async () => {
      if (!isVisible) return;

      const authToken = localStorage.getItem('authToken');

      if (!authToken) {
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings/my-active`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 401) {
        localStorage.removeItem('authToken');
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setActiveListings(data);
      }
    };

    fetchListings();
    fetchActiveListings();
  }, [dispatch, navigate, isVisible]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const ios = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  const inStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
  const withSafeArea = ios && inStandaloneMode;

  return (
    <>
      {/* <InstallPwa /> */}
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h4" gutterBottom>Услуги</Typography>

          {loading ? <LinearProgress /> : <Box height='4px' />}

          {
            listings.filter(l => !l.isClosed && !l.hasAcceptedOffer).map((listing, i) => (
              <>
                {i === 0 && <InstallPwaCard />}
                {i === 4 && <CreateListingCard />}
                <Card
                  key={listing.id}
                  onClick={() => navigate(`/listing/${listing.id}`)}>

                  <CardActionArea>

                    <CardHeader
                      avatar={<Avatar
                        sx={{ bgcolor: 'primary.light' }}
                        src={listing.photoUrl}
                        alt={listing.name}
                      />}

                      title={listing.name}
                      subheader={listing.ratingsCount > 0
                        ? `Рекомендуют ${listing.avarageRating.toFixed(2) * 100}% (${listing.ratingsCount})`
                        : 'Нет отзывов'}
                      action={<Typography variant="caption" color="text.secondary">
                        {new Date(listing.createdAt).toLocaleDateString()}
                      </Typography>}
                    />

                    {
                      listing.photos?.find(p => p.order === 0) &&
                      <CardMedia
                        component="img"
                        image={`${process.env.REACT_APP_BE_URL}${listing.photos.find(p => p.order === 0).filePath}`}
                        alt={listing.description}
                        sx={{ maxHeight: '25vh' }}
                      />
                    }

                    <CardContent>
                      <Typography variant="h6" color="text.primary">
                        {listing.title}
                      </Typography>
                      <Category categoryId={listing.categoryId} />
                      <Stack direction={'row'} alignItems="baseline" spacing={1}>
                        {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                          {listing.askedPrice} руб.
                        </Typography>}
                        <Typography variant="body2" color="text.secondary">
                          {listing.location}
                        </Typography>
                      </Stack>

                      <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                        {listing.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}

        </Stack>

      </Container>

      <Stack spacing={1} sx={{ bottom: withSafeArea ? 116 : 90, position: 'fixed', mx: 1 }}>
        {activeListings?.myActiveListings && activeListings.myActiveListings.map(listing => (
          <SnackbarContent
            message={`Активный заказ: ${listing.title}`}
            action={
              <Button color='secondary' size="small" onClick={() => navigate(`/listing/${listing.id}`)}>
                Открыть
              </Button>}
          />))}

        {activeListings?.activeListingsWithMyOffers && activeListings.activeListingsWithMyOffers.map(listing => (
          <SnackbarContent
            message={`Активный заказ: ${listing.title}`}
            action={
              <Button color='secondary' size="small" onClick={() => navigate(`/listing/${listing.id}`)}>
                Открыть
              </Button>}
          />))}
      </Stack>
    </>
  );
};

export default Listings;
