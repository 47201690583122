// pages/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Stack, Avatar, Container, Alert, CardHeader, IconButton, LinearProgress } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const User = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/${userId}`);

        if (response.status === 401) {
          navigate('/login', { replace: true });
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setData(data);
        } else {
          throw new Error('Failed to fetch ratings');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchRatings();
    }
  }, [navigate, userId]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Stack direction='row' spacing={1} alignItems='flex-start'>
          <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>Отзывы</Typography>
        </Stack>
        <LinearProgress />
      </Container>
    );
  }

  return (

    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='flex-start'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom>Отзывы</Typography>
      </Stack>
      <Stack spacing={1}>
        {error && <Alert severity="error">{error}</Alert>}

        <Stack direction={'row'} alignItems="center" spacing={1}>
          <Avatar
            sx={{ bgcolor: 'primary.light' }}
            src={data?.photoUrl}
            alt={`${data?.name}`}
          />
          <Typography variant="subtitle1" color="text.primary">
            {data?.name}
            {/* <br /> */}
            {/* Рекомендуют {avarageRating.toFixed(2) * 100}% ({ratingsCount}) */}
          </Typography>
        </Stack>

        {data?.ratings.length > 0 ? (
          data.ratings.map((rating) => (
            <Card key={rating.createdAt}>
              <CardHeader
                avatar={<Avatar
                  sx={{ bgcolor: 'primary.light' }}
                  src={rating.reviewerPhotoUrl}
                  alt={`${rating.reviewerName}`} />}

                title={`${rating.reviewerName}`}
                subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                action={<Typography variant="caption" color="text.secondary">
                  {new Date(rating.createdAt).toLocaleDateString()}
                </Typography>}
              />
              <CardContent>
                <Typography variant="body1">{rating.comment}</Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>Нет отзывов</Typography>
        )}
      </Stack>
    </Container>
  );
}

export default User;