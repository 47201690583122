import React, { useState, useEffect } from "react";
import { Card, CardActionArea, CardContent, CardMedia, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const InstallPwaCard = () => {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installApp = async () => {
    if (isIos) {
      navigate('/install-ios');
      return;
    }

    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === "accepted") {
      console.log("Пользователь установил приложение");
    } else {
      console.log("Пользователь отклонил установку");
    }

    setDeferredPrompt(null);
    setIsVisible(false);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("Приложение установлено");
      setIsVisible(false);
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipad|ipod/.test(userAgent);
    const inStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

    setIsIos(ios);
    setIsInStandaloneMode(inStandaloneMode);
  }, []);

  if (!isVisible && (!isIos || isInStandaloneMode)) {
    return null;
  }

  return (
    <Card onClick={installApp}>
      <CardActionArea>
        <CardMedia
          component="img"
          image="/banner.jpeg"
          alt="ПрофиСфера" />
        <CardContent>
          <Stack direction={'row'} spacing={1} alignItems={"center"}>
            <Typography variant="body1">
              Установите приложение на телефон для быстрого доступа к услугам
            </Typography>
            <IconButton aria-label="download" size="large" color="secondary" onClick={installApp}>
              <GetAppRoundedIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InstallPwaCard;
