// pages/CreateListing.js
import React, { useEffect, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  FormControlLabel,
  Stack,
  Link,
  Checkbox,
  FormGroup,
  IconButton,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';

const CreateListing = () => {
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(true);
  const [location, setLocation] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [askedPrice, setAskedPrice] = useState('');
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const listing = useSelector((state) =>
    state.listings.myListings.find((l) => l.id === id)
  );

  useEffect(() => {
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login?to=' + encodeURI(id ? `/create-listing/${id}` : '/create-listing'), { replace: true });
    }
  }, [navigate, id]);

  useEffect(() => {
    if (listing) {
      setTitle(listing.title);
      setDescription(listing.description);
      setIsPhoneNumberVisible(listing.isPhoneNumberVisible);
      setLocation(listing.location);
      setCategoryId(listing.categoryId);
      setAskedPrice(listing.askedPrice || '');
    }
  }, [listing]);

  const MAX_TOTAL_FILE_SIZE = 16 * 1024 * 1024;

  const handleFileChange = (event) => {
    try {
      setIsLoading(true);
      const files = compressFiles(event.target.files);

      let totalSize = 0;

      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }

      if (totalSize > MAX_TOTAL_FILE_SIZE) {
        setError('Общий размер файлов не должен превышать 16 МБ. Некоторые файлы не будут загружены.');
      } else {
        setError(null);
        setPhotos(files);
      }
    } catch (error) {
      setError(error.message);
    }
    finally {
      setIsLoading(false);
    }
  };

  const compressFiles = (files) => {
    const compressedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file) continue;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 1000;
          const maxHeight = 1000;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => compressedFiles.push(blob),
            "image/jpeg",
            0.9 // '0.9' is quality (90%)
          );
        };
      };
    }

    return compressedFiles;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/login', { replace: true });
        return;
      }

      const formData = new FormData();

      formData.append("title", title);
      formData.append("description", description);
      formData.append("askedPrice", askedPrice);
      formData.append("categoryId", categoryId);
      formData.append("location", location);
      formData.append("isProvider", listing?.isProvider ?? false);
      formData.append("isPhoneNumberVisible", isPhoneNumberVisible);

      for (let i = 0; i < photos.length; i++) {
        formData.append("photos", photos[i]);
      }

      let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings/` + (id ? id : ''), {
        method: (id ? 'PUT' : 'POST'),
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (response.status === 401) {
        navigate('/login', { replace: true });
        return;
      }

      if (!response.ok) {
        const text = await response.text();
        setError(text);
        return;
      }

      const responseData = await response.json();
      navigate(`/listing/${responseData.id}`, { replace: true });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Stack direction='row' spacing={1} alignItems='flex-start' sx={{ mb: 2 }}>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4">{listing ? 'Редактировать заказ' : 'Создать заказ'}</Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth required>
              <InputLabel id="category-select-label">Категория</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={categoryId}
                label="Категория"
                onChange={(e) => setCategoryId(e.target.value)}>
                <MenuItem value=""><em>Выберите категорию</em></MenuItem>
                <MenuItem value="2">Строительство и ремонт</MenuItem>
                <MenuItem value="3">Сантехники</MenuItem>
                <MenuItem value="4">Электрики</MenuItem>
                <MenuItem value="5">Ремонт, установка техники</MenuItem>
                <MenuItem value="6">Грузчики</MenuItem>
                <MenuItem value="7">Спецтехника</MenuItem>
                <MenuItem value="8">Транспортировка</MenuItem>
                <MenuItem value="9">Автосервис</MenuItem>
                <MenuItem value="10">Бухгалтерия и финансы</MenuItem>
                <MenuItem value="11">Детские сады, няни, сиделки</MenuItem>
                <MenuItem value="12">Красота и здоровье</MenuItem>
                <MenuItem value="13">Курсы, семинары, тренинги</MenuItem>
                <MenuItem value="14">Мастер на час</MenuItem>
                <MenuItem value="15">Мебель, ремонт и изготовление</MenuItem>
                <MenuItem value="16">Обучение, репетиторство</MenuItem>
                <MenuItem value="17">Пошив, ремонт одежды, обуви</MenuItem>
                <MenuItem value="18">Праздники и мероприятия</MenuItem>
                <MenuItem value="19">Работа с текстом, переводы</MenuItem>
                <MenuItem value="20">Реклама и полиграфия</MenuItem>
                <MenuItem value="21">Ремонт компьютеров, мобильных устройств</MenuItem>
                <MenuItem value="22">Слесари</MenuItem>
                <MenuItem value="23">Создание сайтов, поддержка ПО</MenuItem>
                <MenuItem value="24">Уборка помещений, территорий</MenuItem>
                <MenuItem value="25">Фото- и видеосъёмка</MenuItem>
                <MenuItem value="26">Юридические услуги</MenuItem>
                <MenuItem value="1">Без категории</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Alert severity="info" >
            Если вы предоставляете услугу, <Link href={process.env.REACT_APP_MASTER_URL}>переключитесь в режим мастера</Link>.
          </Alert>

          <TextField
            fullWidth
            label="Воспользуюсь услугой"
            value={title}
            multiline
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label="Описание"
            value={description}
            multiline
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Местоположение"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Цена (необязательно)"
            type="number"
            value={askedPrice}
            onChange={(e) => setAskedPrice(e.target.value)}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={isPhoneNumberVisible}
                onChange={(e) => setIsPhoneNumberVisible(e.target.checked)} />}
              label="Показывать мой номер телефона всем" />
          </FormGroup>

          <Typography variant="body1" gutterBottom>Фотографии</Typography>
          <input type="file" accept="image/*" multiple onChange={handleFileChange} />

          {listing && photos.length === 0 &&
            <Alert severity="info" >Фотографии не изменены</Alert>
          }

          {error && <Alert severity="error" >{error}</Alert>}

          <Typography variant='body2' align="center">
            Нажимая на кнопку ниже, вы соглашаетесь с&nbsp;
            <Link component={RouterLink} to="/privacy">Политикой конфиденциальности</Link>.
          </Typography>

          {isLoading && <LinearProgress />}

          <Button type="submit" color='secondary' fullWidth variant='contained' disabled={isLoading}>
            Готово
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default CreateListing;
