// pages/InstalliOS.js
import React from 'react';
import { Typography, Container, Stack, IconButton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const InstalliOS = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='flex-start'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>Установка приложения</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant='body1'>
          Чтобы получать уведомления, добавьте приложение на главный экран.
        </Typography>

        <Typography variant="body2">Нажмите "Поделиться":</Typography>
        <img src="/ios-share.jpg" alt="iOS Share" style={{ width: '100%', marginBottom: '8px' }} />
        <Typography variant="body2">Выберите "На экран &laquo;Домой&raquo;":</Typography>
        <img src="/ios-add-to-home.jpg" alt="iOS Add to Home" style={{ width: '100%', marginBottom: '8px' }} />
        <Typography variant="body2">Иконка появится на домашнем экране:</Typography>
        <Box display="flex" justifyContent="center">
          <img src="/screen.png" alt="Home screen" style={{ width: '40%', marginBottom: '8px' }} />
        </Box>
      </Stack>
    </Container>
  );
}

export default InstalliOS;