import React from "react";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateListingCard = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardMedia
        component="img"
        image="/banner-wide.jpeg"
        alt="ПрофиСфера" />
      <CardContent>
        <Typography variant="body1">
          Закажите услугу мастера в Якутске
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" size="small" onClick={() => navigate('/create-listing')}>Создать заказ</Button>
      </CardActions>
    </Card>
  );
};

export default CreateListingCard;
