// slices/listingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllListings = createAsyncThunk(
  'listings/fetchAllListings',
  async ({ navigate }, { rejectWithValue }) => {
    try {
      let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings?` + new URLSearchParams({
        isProvider: true,
      }));

      if (response.status === 401) {
        navigate('/login', { replace: true });
        return rejectWithValue('No auth token');
      }

      if (!response.ok) {
        return rejectWithValue('Failed to fetch listings');
      }

      const listingsData = await response.json();
      return listingsData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchListing = createAsyncThunk(
  'listings/fetchListing',
  async ({ id, authToken, navigate }, { rejectWithValue }) => {
    try {
      let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings/${id}`, {
        headers: authToken ? {
          'Authorization': `Bearer ${authToken}`,
        } : undefined,
      });

      if (response.status === 401) {
        navigate('/login', { replace: true });
        return rejectWithValue('No auth token');
      }

      if (!response.ok) {
        return rejectWithValue(await response.text());
      }

      const listingData = await response.json();
      return listingData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  'listings/fetchProfile',
  async ({ authToken, navigate }, { rejectWithValue }) => {
    try {
      let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/my?` + new URLSearchParams({
        isProvider: false,
      }), {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.status === 401) {
        navigate('/login', { replace: true });
        return rejectWithValue('No auth token');
      }

      if (!response.ok) {
        return rejectWithValue(await response.text());
      }

      const profileData = await response.json();
      return profileData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {
    listings: [],
    myListings: [],
    loading: true,
    error: null,
    profile: null,
  },
  reducers: {
    upsertListing: (state, action) => {
      const updatedListing = action.payload;
      const index1 = state.listings.findIndex(listing => listing.id === updatedListing.id);

      if (index1 !== -1) {
        state.listings[index1] = updatedListing;
      } else if (updatedListing.isProvider === true) {
        state.listings.unshift(updatedListing);
      }

      const index2 = state.myListings.findIndex(listing => listing.id === updatedListing.id);

      if (index2 !== -1) {
        state.myListings[index2] = updatedListing;
      } else {
        state.myListings.unshift(updatedListing);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllListings.fulfilled, (state, action) => {
        state.listings = Array.isArray(action.payload) ? action.payload : [];
        state.loading = false;
      })
      .addCase(fetchAllListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchListing.fulfilled, (state, action) => {
        const updatedListing = action.payload;
        const index1 = state.listings.findIndex(listing => listing.id === updatedListing.id);

        if (index1 !== -1) {
          state.listings[index1] = updatedListing;
        } else if (updatedListing.isProvider === true) {
          state.listings.push(updatedListing);
        }

        const index2 = state.myListings.findIndex(listing => listing.id === updatedListing.id);

        if (index2 !== -1) {
          state.myListings[index2] = updatedListing;
        } else {
          state.myListings.push(updatedListing);
        }

        state.loading = false;
      })
      .addCase(fetchListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { upsertListing } = listingsSlice.actions;

export default listingsSlice.reducer;
