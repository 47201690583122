// pages/Ratings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Alert,
  Avatar,
  Checkbox,
  FormControlLabel,
  CardHeader
} from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { jwtDecode } from 'jwt-decode';

const Ratings = ({ listing }) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const userId = authToken && jwtDecode(authToken).sub;
  const [ratings, setRatings] = useState([]);
  const [wouldRecommend, setWouldRecommend] = useState(true);
  const [error, setError] = useState(null);
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/${listing?.creatorId}`);

        if (response.status === 401) {
          navigate('/login', { replace: true });
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setRatings(data?.ratings);
        } else {
          throw new Error('Failed to fetch ratings');
        }
      } catch (error) {
        setError(error.message);
      }
    };

    if (listing?.creatorId) {
      fetchRatings();
    }
  }, [navigate, listing?.creatorId, ratingSubmitted]);

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/ratings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ listingId: listing.id, wouldRecommend, comment }),
      });

      if (response.status === 401) {
        navigate('/login', { replace: true });
        return;
      }

      if (!response.ok) {
        throw new Error(await response.text());
      }

      setRatingSubmitted(true);

    } catch (error) {
      setError(error.message);
    }
  };

  const aboutWho = () => {
    if (listing.creatorId === userId) {
      const acceptedOffer = listing.offers?.find(o => o.isAccepted === true);
      return `Про ${acceptedOffer?.name}`;
    } else if (listing.offers?.some(o => o.creatorId === userId)) {
      return `Про ${listing.name}`;
    } else {
      return 'Про участника';
    }
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>Отзывы</Typography>
      {listing.isClosed && (
        <>
          {!ratingSubmitted && (listing.creatorId === userId || listing.offers?.some(o => o.creatorId === userId)) && listing.hasAcceptedOffer && (
            <Card>
              <CardHeader title="Оставить отзыв" subheader={aboutWho()} />
              <CardContent>
                <form onSubmit={handleRatingSubmit}>
                  <FormControlLabel
                    control={<Checkbox
                      value={wouldRecommend}
                      onChange={(e) => setWouldRecommend(e.target.checked)} />}
                    label="Рекомендую" />
                  <TextField
                    label="Комментарий"
                    fullWidth
                    margin="normal"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                    multiline
                  />
                  {error && <Alert severity="error">{error}</Alert>}
                  <Button variant='contained' type="submit">Оставить отзыв</Button>
                </form>
              </CardContent>
            </Card>
          )}
          {ratingSubmitted && <Alert severity="success">Спасибо за отзыв!</Alert>}
        </>
      )}

      {ratings.length > 0 ? (
        ratings.map((rating) => (
          <Card key={rating.createdAt}>
            <CardHeader
              avatar={<Avatar
                sx={{ bgcolor: 'primary.light' }}
                src={rating.reviewerPhotoUrl}
                alt={`${rating.reviewerName}`} />}

              title={`${rating.reviewerName}`}
              subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
              action={<Typography variant="caption" color="text.secondary">
                {new Date(rating.createdAt).toLocaleDateString()}
              </Typography>}
            />
            <CardContent>
              <Typography variant="body1">{rating.comment}</Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>Нет отзывов</Typography>
      )}
    </>
  );
}

export default Ratings;