// pages/Profile.js
import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  Box,
  Tabs,
  Tab,
  Avatar,
  Container,
  Alert,
  CardActionArea,
  CardMedia,
  Link,
  IconButton,
  CardHeader,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  isPersonalNotificationsEnabled,
  disablePersonalNotifications,
  enablePersonalNotifications,
  getSubscribedTopics
} from '../utils/pushy';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../slices/listingsSlice';

const Profile = () => {
  const { profile, loading, error } = useSelector((state) => state.listings);
  const [tabIndex, setTabIndex] = useState(() => Number(localStorage.getItem('profileTabId')));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [settingsError, setSettingsError] = useState(null);
  const [switchingNotifications, setSwitchingNotifications] = useState(false);
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(isPersonalNotificationsEnabled());
  const [topics, setTopics] = useState(getSubscribedTopics());

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login', { replace: true });
      return;
    }

    dispatch(fetchProfile({ authToken, navigate }));
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    localStorage.setItem('profileTabId', newValue);
    setTabIndex(newValue);
  };

  const handleNotificationsChange = async (event) => {
    try {
      const enabled = isPersonalNotificationsEnabled();
      setSwitchingNotifications(true);

      const authToken = localStorage.getItem('authToken');

      if (enabled) {
        await disablePersonalNotifications({ authToken, onAuthFail: () => navigate('/login') });
      } else {
        await enablePersonalNotifications({ authToken, onAuthFail: () => navigate('/login') });
      }
    } catch (error) {
      setSettingsError(error.message);
    } finally {
      setIsNotificationsEnabled(isPersonalNotificationsEnabled());
      setSwitchingNotifications(false);
    }
  };

  const testNotification = async () => {
    try {
      setSwitchingNotifications(true);

      const authToken = localStorage.getItem('authToken');

      await enablePersonalNotifications({ authToken, onAuthFail: () => navigate('/login') });

      await fetch(`${process.env.REACT_APP_BE_URL}/be/deviceTokens/test`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });
    } catch (error) {
      setSettingsError(error.message);
    } finally {
      setSwitchingNotifications(false);
    }
  };

  const topicsToSubscribe = Object.keys(topics).filter(topic => topics[topic]);
  const ios = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Typography variant="h4" gutterBottom>Профиль</Typography>

      {loading && <LinearProgress />}

      <Stack spacing={1}>
        <Stack direction={'row'} alignItems="center" spacing={1}>
          <Avatar
            sx={{ bgcolor: 'primary.light' }}
            src={profile?.photoUrl}
            alt={profile?.name}
          />
          <Stack>
            <Typography variant="subtitle1">
              {profile?.name}
            </Typography>
            <Typography variant="caption">
              +{profile?.phoneNumber}
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }} />
          <IconButton aria-label="edit" onClick={() => navigate(`/edit-profile`)} >
            <EditIcon />
          </IconButton>
        </Stack>

        {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}

        <Button endIcon={<LogoutIcon />} variant='outlined' onClick={() => navigate('/login', { replace: true })}>
          Выйти
        </Button>

        <Link href={process.env.REACT_APP_MASTER_URL}>
          Переключиться в режим мастера
        </Link>

        <Link href="https://t.me/profisfera_ru">
          Написать в поддержку
        </Link>

        <Link component={RouterLink} to="/privacy">
          Политика в отношении обработки персональных данных
        </Link>

      </Stack>

      <Tabs
        sx={{ my: 1 }}
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable">
        <Tab icon={<EditNotificationsIcon />} />
        <Tab label="Мои заказы" />
        <Tab label="Мои заявки" />
        <Tab label="Отзывы" />
      </Tabs>

      {tabIndex === 0 && (
        <Stack spacing={1}>
          {settingsError && (settingsError.includes('Add to Home')
            ? <Alert severity="warning">
              Для получения уведомлений,<br /><Link component={RouterLink} to="/install-ios">установите приложение на домашний экран</Link>.</Alert>
            : (settingsError.includes('permission')
              ? (ios
                ? <Alert severity="warning">Разрешите приложению получать уведомления в настройках системы.</Alert>
                : <Alert severity="warning">Разрешите уведомления в настройках браузера.</Alert>)
              : <Alert severity="error">{settingsError}</Alert>))}

          {(isNotificationsEnabled || topicsToSubscribe.length > 0) &&
            (ios
              ? <Alert severity="info">Если уведомления не приходят, разрешите приложению получать уведомления в настройках системы.</Alert>
              : <Alert severity="info">Если уведомления не приходят, разрешите уведомления в настройках браузера.</Alert>
            )}

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isNotificationsEnabled} disabled={switchingNotifications} onChange={handleNotificationsChange} />}
              label="Включить персональные уведомления" />

            {isNotificationsEnabled && (
              <Button variant='outlined' onClick={testNotification} disabled={switchingNotifications} size='small' >
                Получить тестовое уведомление
              </Button>
            )}
          </FormGroup>

        </Stack>
      )}

      {tabIndex === 1 && (
        <Stack spacing={1}>
          {profile?.listings.length > 0 ? (
            profile.listings.map((listing) => (

              <Card
                key={listing.id}
                onClick={() => navigate(`/listing/${listing.id}`)}>

                <CardActionArea>
                  {
                    listing.photos.length !== 0 &&
                    <CardMedia
                      component="img"
                      image={`${process.env.REACT_APP_BE_URL}${listing.photos[0].filePath}`}
                      alt={listing.description}
                      sx={{ height: 240 }}
                    />
                  }

                  <CardContent>
                    <Typography variant="h6" color="text.primary">
                      {listing.title}
                    </Typography>
                    <Stack direction={'row'} alignItems="baseline" spacing={1} >
                      {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                        {listing.askedPrice} руб.
                      </Typography>}
                      <Typography variant="body2" color="text.secondary">
                        {listing.location}
                      </Typography>
                    </Stack>

                    {/* <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                      {listing.description}
                    </Typography> */}

                    <Typography variant="body2" color="text.secondary">
                      {listing.isClosed ? 'Заказ закрыт' : 'Заказ открыт'}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {listing.hasAcceptedOffer ? 'Есть принятая заявка' : ''}
                    </Typography>

                    <Typography variant="caption" color="text.secondary">
                      {new Date(listing.createdAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body1">Нет заказов</Typography>
          )}
        </Stack>
      )}

      {tabIndex === 2 && (
        <Stack spacing={1}>
          {profile?.offers.length > 0 ? (
            profile.offers.map((offer) => (
              <Card key={offer.id} onClick={() => navigate(`/listing/${offer.listingId}`)}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="subtitle1">{offer.price} руб</Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {offer.isAccepted ? 'Заявка принята' : 'Заявка еще не принята'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(offer.createdAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body1">Нет заявок</Typography>
          )}
        </Stack>
      )}

      {tabIndex === 3 && (
        <Stack spacing={1}>
          {profile?.ratings.length > 0 ? (
            profile.ratings.map((rating) => (
              <Card key={rating.createdAt}>
                <CardHeader
                  avatar={<Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    src={rating.reviewerPhotoUrl}
                    alt={`${rating.reviewerName}`} />}

                  title={`${rating.reviewerName}`}
                  subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                  action={<Typography variant="caption" color="text.secondary">
                    {new Date(rating.createdAt).toLocaleDateString()}
                  </Typography>}
                />
                <CardContent>
                  <Typography variant="body1">{rating.comment}</Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body1">Нет отзывов</Typography>
          )}
        </Stack>
      )}

    </Container >
  );
};

export default Profile;
